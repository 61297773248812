var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm._m(0);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "dashboard flex justify-center items-center mx-auto"
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/images/Illustrasi.svg")
    }
  })]);

}]

export { render, staticRenderFns }